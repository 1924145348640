import React, { Component } from 'react';

// import my_profile from './../images/my-profile.jpeg'
import video from './../files/video.mp4'

export default class Portofolio extends Component {

  state = {
    video_status:false
  }

  toggleVideo = () => {
    this.setState({
      video_status:!this.state.video_status
    })
  }

  render(){
    return (
      <section className={`portofolio ${this.props.portofolio_status ? 'open' : 'close'}`}>
        <div className="content">
          {
            !this.state.video_status && <button className="close" onClick={() => this.props.togglePortofolio()}>x</button>
          }
          <div className="top">
            <h1>Portofolio</h1>
          </div>
          <div className="projects">
            <div className="first" onClick={() => this.toggleVideo()}>
              <div className="overlay">
                <h3>React Native App</h3>
              </div>
            </div>
          </div>
        </div>
        <div className={`video-container ${ this.state.video_status ? 'open' : 'close'} `}>
          <video id="video" controls autoPlay muted>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button className="close-video" onClick={() => this.toggleVideo()}>X</button>
        </div>
      </section>
    );
  }
}
