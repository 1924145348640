import React, { Component } from 'react';

import Me from './images/photo_1.jpeg';

import { ReactComponent as Fb } from './images/facebook.svg';
import { ReactComponent as In } from './images/linkedin.svg';
import { ReactComponent as Email } from './images/mail.svg';
import { ReactComponent as Phone } from './images/phone.svg';

import './App.scss';
import More from './components/more';
import Portofolio from './components/portofolio';


class App extends Component {

  state={
    menu_status:false,
    more_status:false,
    portofolio_status:false
  }

  toggleMore = () => {
    this.setState({
      more_status:!this.state.more_status
    })
  }

  togglePortofolio = () => {
    this.setState({
      portofolio_status:!this.state.portofolio_status
    })
  }

  render(){
    return (
      <div className="general-container">
        <div className="buttons">
          <button onClick={() => this.togglePortofolio()}>PORTOFOLIO</button>
          <button onClick={() => this.toggleMore()}>MORE</button>
        </div>

        <div className="content">
          <div className="img-container">
            <img src={ Me } alt="me"/>
          </div>

          <h1>ADRIAN POPESCU</h1>
          <h2>Web | Mobile <span className="yellow">Developer</span></h2>

          <p>I'm working as Web | Mobile Developer since 2018. I have developed projects such as <span className="yellow">mobile applications</span>, <span className="yellow">presentation sites</span> and <span className="yellow">web applications</span>.</p>

          <div className="socials">
            <h6>GET IN TOUCH</h6>
            <ul>
              <li><a href="mailto:adrian2.popescu3@gmail.com" target="_blank" rel="noopener noreferrer"><Email/></a></li>
              <li><a href="https://www.facebook.com/ady.popescu.940" target="_blank" rel="noopener noreferrer"><Fb/></a></li>
              <li><a href="https://www.linkedin.com/in/adrian-popescu-40b118196/" target="_blank" rel="noopener noreferrer"><In/></a></li>
              <li><a href="tel:+40756762953" target="_blank" rel="noopener noreferrer"><Phone/></a></li>
            </ul>
          </div>
        </div>

        <More
          more_status={this.state.more_status}
          toggleMore={this.toggleMore}
        />

        <Portofolio
          portofolio_status={this.state.portofolio_status}
          togglePortofolio={this.togglePortofolio}
        />

      </div>
    );
  }
}

export default App;