import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';

// import my_profile from './../images/photo_1.jpeg'
import cv from './../files/cv.pdf';

const reactnative_percentage = 70;
const reactjs_percentage = 73;
const laravel_percentage = 31;
const ruby_percentage = 34;
const nodejs_percentage = 55;
const awsservices_percentage = 43;
const boostrap_percentage = 82;
const fouundation_percentage = 82;
const mysql_percentage = 68;
export default class More extends Component {

  render(){
    return (
      <section className={`more ${this.props.more_status ? 'open' : 'close'}`}>
        <div className="content">
          <button className="close" onClick={() => this.props.toggleMore()}>x</button>
          <div className="top">
            {/* <div className="img-container">
              <img src={my_profile} alt="me"/>
            </div> */}
            <h1>About Me</h1>
            <div className="about-me">
              <ul>
                <li><span>Name: </span>Popescu Adrian</li>
                <li><span>Born in: </span>1997, Romania</li>
                <li><span>Location: </span>Suceava, Romania</li>
                <li><span>Phone: </span><a href="tel:+40756762953">+40756762953</a></li>
                <li><span>Email: </span><a href="mailto:adrian2.popescu3@gmail.com">adrian2.popescu3@gmail.com</a> </li>
              </ul>
            </div>
            <h1>Skills</h1>
            <div className="skills">
              <ul>
                <li>
                  <div className="top-skill">
                    <p>React Native</p>
                    <p className="small">Projects: 4</p>
                  </div>
                  <ProgressBar now={ reactnative_percentage } label={ reactnative_percentage + '%' }/>
                </li>
                <li>
                  <div className="top-skill">
                    <p>React JS</p>
                    <p className="small">Projects: 5</p>
                  </div>
                  <ProgressBar now={ reactjs_percentage } label={ reactjs_percentage + '%' }/>
                </li>
                <li>
                  <div className="top-skill">
                    <p>Laravel</p>
                    <p className="small">Projects: 2</p>
                  </div>
                  <ProgressBar now={ laravel_percentage } label={ laravel_percentage + '%' }/>
                </li>
                <li>
                  <div className="top-skill">
                    <p>Ruby on Rails</p>
                    <p className="small">Projects: 2</p>
                  </div>
                  <ProgressBar now={ ruby_percentage } label={ ruby_percentage + '%' }/>
                </li>
                <li>
                  <div className="top-skill">
                    <p>Node JS & Express JS</p>
                    <p className="small">Projects: 3</p>
                  </div>
                  <ProgressBar now={ nodejs_percentage } label={ nodejs_percentage + '%' }/>
                </li>
                <li>
                  <div className="top-skill">
                    <p>MySQL & PostgreSQL</p>
                    <p className="small">Projects: 5</p>
                  </div>
                  <ProgressBar now={ mysql_percentage } label={ mysql_percentage + '%' }/>
                </li>
                <li>
                  <div className="top-skill">
                    <p>AWS Services</p>
                    <p className="small">Projects: 1</p>
                  </div>
                  <ProgressBar now={ awsservices_percentage } label={ awsservices_percentage + '%' }/>
                </li>
                <li>
                  <div className="top-skill">
                    <p>Boostrap</p>
                    <p className="small">Projects: 50%</p>
                  </div>
                  <ProgressBar now={ boostrap_percentage } label={ boostrap_percentage + '%' }/>
                </li>
                <li>
                  <div className="top-skill">
                    <p>Foundation</p>
                    <p className="small">Projects: 50%</p>
                  </div>
                  <ProgressBar now={ fouundation_percentage } label={ fouundation_percentage + '%' }/>
                </li>
              </ul>
            </div>
          </div>
          <div className="resume">
            <a href={cv} className="button" download>DOWNLOAD CV</a>
          </div>
        </div>
      </section>
    );
  }
}
